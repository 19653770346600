import type { OnboardingOrganizationFields } from "./components/organization/schema";

const EMPLOYER_SIGN_UP_PARAMS = ["invitation_key"] as const;

export const isEmployerParamUsed = (params: URLSearchParams) => {
  return EMPLOYER_SIGN_UP_PARAMS.some((param) => params.has(param));
};

type PreparedOrganizationInfoValues = Omit<
  OnboardingOrganizationFields,
  "google_full_location"
> & {
  google_full_location?: Omit<
    OnboardingOrganizationFields["google_full_location"],
    string
  >;
};

export const prepareOrganizationInfoValues = (
  values: OnboardingOrganizationFields,
): PreparedOrganizationInfoValues => {
  const newValues = { ...values };
  if (typeof newValues.google_full_location === "string") {
    delete newValues.google_full_location;
  }
  return newValues as PreparedOrganizationInfoValues;
};
