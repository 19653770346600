import { useCallback } from "react";

import { useUser } from "@js/apps/common/hooks/use-user";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import { formatAIGeneratedJobToFormData } from "@js/apps/employer/hooks/generate-ai/utils";
import { GENERATE_AI_JOB_DESCRIPTION } from "@js/apps/jobs/apps/create-job/action-types";
import { useJobRole } from "@js/apps/roles/hooks";
import { useGetSkillsQuery } from "@js/apps/skills/api";
import type { ReduxFormConfiguration } from "@js/forms/components/redux-form";
import { useNavigate } from "@js/hooks";
import type { AppDispatch } from "@js/store";
import type { AIGeneratedJob, ManagedJob } from "@js/types/jobs";

import { generateJobDescriptionUsingAI } from "../../actions";
import type { JobOverviewFormData } from "../../forms/job-overview";

type JobOverviewFormConfigProps = ReduxFormConfiguration<
  JobOverviewFormData,
  any
>;

type useGenerateAIConfig = () => {
  onSubmit: JobOverviewFormConfigProps["onSubmit"];
  onSubmitSuccess: JobOverviewFormConfigProps["onSubmitSuccess"];
};

export const useGenerateAI: useGenerateAIConfig = () => {
  const { data: employerProfile } = useManagedEmployer();

  const userProfile = useUser();
  const navigate = useNavigate();
  const { roleOptions: roles } = useJobRole();
  const { data: skills } = useGetSkillsQuery({});

  const onSubmit: JobOverviewFormConfigProps["onSubmit"] = useCallback(
    async (values: JobOverviewFormData, dispatch: AppDispatch) =>
      dispatch(
        generateJobDescriptionUsingAI(
          employerProfile?.id || userProfile?.id,
          values.job_overview,
        ),
      ),
    [employerProfile, userProfile],
  );

  const formatToJobData = useCallback(
    (result: AIGeneratedJob): Partial<ManagedJob> => {
      return formatAIGeneratedJobToFormData({
        data: result,
        roles,
        skills: skills || [],
      });
    },
    [roles, skills],
  );

  const onSubmitSuccess: JobOverviewFormConfigProps["onSubmitSuccess"] =
    useCallback(
      (result: AIGeneratedJob, dispatch: AppDispatch) => {
        dispatch({
          type: GENERATE_AI_JOB_DESCRIPTION,
          payload: formatToJobData(result),
        });
        navigate("/jobs/add_new");
      },
      [formatToJobData, navigate],
    );

  return { onSubmit, onSubmitSuccess };
};
