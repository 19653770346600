import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import type { Dispatch } from "redux";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { isEqual, isObject, omit } from "underscore";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import { useHandleTransaction } from "@js/apps/payments/hooks/use-handle-transaction";
import { Snackbar } from "@js/components/snackbar";
import { useGoBackHistory, useNavigate } from "@js/hooks/";
import { useIdParam } from "@js/hooks/use-id-param";
import { isErrorWithErrors } from "@js/types/errors";

import {
  useGetEmployerOfferQuery,
  useUpdateEmployerOfferMutation,
} from "../../api";
import {
  ReviewBeforeSendingModal,
  ReviewBeforeSendingModalContent,
} from "../../components";
import { OFFER_FIELDS } from "../../constants";
import type { EditOfferFormData, UpdateOfferDataReturnData } from "../../types";
import { getEditOfferInitialValues } from "../../utils";
import { useCreateOrEditOfferSnackbar } from "../create-or-edit-offer-snackbar";

type OnSuccessResultData = UpdateOfferDataReturnData & {
  transactionError?: Record<string, string>;
};

export const useEditOffer = (): {
  loading: boolean;
  isTransactionProceeding: boolean;
  initialValues: Partial<EditOfferFormData>;
  onSubmit: (values: EditOfferFormData) => void;
  onSubmitSuccess: (
    result: OnSuccessResultData | undefined,
    methodDispatch: Dispatch<any>,
    props: DecoratedFormProps<EditOfferFormData>,
  ) => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = useGoBackHistory();

  const [loading, setLoading] = useState(true);
  const jobId = useIdParam();
  const offerId = useIdParam("offerId");
  const isNodeStaff = useIsNodeStaff();
  const { isLoading: isLoadingEmployerProfile } = useManagedEmployer();

  const [updateOffer] = useUpdateEmployerOfferMutation();
  const {
    data: offer,
    isFetching: isFetchingOffer,
    error: fetchingOfferError,
  } = useGetEmployerOfferQuery(offerId ? offerId : skipToken);

  const { handleTransactionCreated, loading: transactionLoading } =
    useHandleTransaction();

  const bidDetails = offer?.bid;

  const { displayOfferSnackbar, loadingTopBar } = useCreateOrEditOfferSnackbar({
    jobId,
    bidDetails,
  });

  useEffect(() => {
    try {
      if (isFetchingOffer) return;

      if (fetchingOfferError) {
        // in case of error user will be redirected immediately because of axios interceptor
        const errorMessage =
          (isObject(fetchingOfferError) && fetchingOfferError?.data?.detail) ||
          "Could not load offer";

        throw Error(errorMessage);
      }

      setLoading(false);
    } catch (error) {
      const _error = error as { response?: { data: string } };

      Snackbar.error(_error.response?.data || "Something went wrong");
      // don't redirect immediately to make sure user see error in context of visited offer
      setTimeout(() => {
        const lastPage = new URLSearchParams(location.state?.prevSearch).get(
          "page",
        );
        const pagination = lastPage ? `?page=${lastPage}` : "";
        goBack(`/jobs/${jobId}/proposals/${pagination}`);
      }, 3000);
    }
  }, [
    fetchingOfferError,
    goBack,
    isFetchingOffer,
    jobId,
    location.state?.prevSearch,
    offerId,
  ]);

  const onSubmit = useCallback(
    async (values: EditOfferFormData) => {
      try {
        await new Promise((resolve, reject) => {
          ReviewBeforeSendingModal.open({
            children: (
              <ReviewBeforeSendingModalContent
                onSubmit={resolve}
                values={values}
              />
            ),
            onClose: () => reject("Closed by user"),
          });
        });

        const data = await updateOffer({
          id: Number(offerId),
          data: {
            ...omit(values, "deposit_payment_method"),
            deposit_payment_method_id: !isNodeStaff
              ? values.deposit_payment_method?.id
              : undefined,
          },
        })
          .unwrap()
          .catch((err) => {
            throw new SubmissionError({
              ...err.data,
              [OFFER_FIELDS.deposit_payment_method]:
                err.data.deposit_payment_method_id,
            });
          });

        if (data.payment_transaction && values.deposit_payment_method) {
          const transactionData = await handleTransactionCreated(
            data.payment_transaction,
            values.deposit_payment_method,
          );

          // We should use try catch here but right now we can't as
          // transaction is linked to offer and returned only if offer is created
          // which will cause that we will not be redirected even if offer was created successfully
          // TODO: Try to use try catch when deposit will be migrated to employer level
          if (transactionData?.error) {
            return {
              transactionError: transactionData.error,
              ...data,
            };
          }
        }

        return data;
      } catch (err: unknown) {
        if (isErrorWithErrors(err)) {
          throw new SubmissionError(err.errors);
        }
        throw new SubmissionError({ _error: "Failed to edit offer" });
      } finally {
        ReviewBeforeSendingModal.close();
      }
    },
    [handleTransactionCreated, isNodeStaff, offerId, updateOffer],
  );

  const onSubmitSuccess = useCallback(
    (
      result: OnSuccessResultData | undefined,
      _methodDispatch: Dispatch<any>,
      props: DecoratedFormProps<EditOfferFormData>,
    ) => {
      if (!result) return;

      const values = props.values as EditOfferFormData;
      const initialValues = props.initialValues;

      const lastPage = new URLSearchParams(location.state?.prevSearch).get(
        "page",
      );
      const pagination = lastPage ? `?page=${lastPage}` : "";

      navigate(`/jobs/${result.bid.job.id}/proposals/${pagination}`);

      const depositPaymentMethod = values?.deposit_payment_method;
      const initialDepositPaymentMethod = initialValues?.deposit_payment_method;

      const paymentMethodChanged = !isEqual(
        depositPaymentMethod,
        initialDepositPaymentMethod,
      );

      if (paymentMethodChanged) {
        displayOfferSnackbar({ result, depositPaymentMethod });
      }
    },
    [displayOfferSnackbar, location.state?.prevSearch, navigate],
  );

  const initialValues = React.useMemo(() => {
    return getEditOfferInitialValues(offer);
  }, [offer]);

  return {
    loading:
      loading ||
      isFetchingOffer ||
      transactionLoading ||
      isLoadingEmployerProfile ||
      loadingTopBar,
    initialValues: initialValues || {},
    isTransactionProceeding: transactionLoading,
    onSubmit,
    onSubmitSuccess,
  };
};
