import { API } from "@js/api";
import type { JobOwner } from "@js/types/admin";
import type {
  ATSIntegration,
  ATSIntegrationError,
  Employer,
  EmployerMyTalent,
  EmployerTeamMemberType,
  InvitedTeamMember,
} from "@js/types/employer";
import type { PaginatedResult } from "@js/types/generic";
import type { ATSJob } from "@js/types/jobs";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

import type { AssignOwnersFormDataTypes } from "./components/assign-owners-modal";
import { processGetMyTalentParams } from "./utils/my-talent";

export type EmployerDefaultJobOwners = {
  default_op_owners: JobOwner[];
  default_sale_owners: JobOwner[];
};

export type NewDefaultJobOwnersPayload = AssignOwnersFormDataTypes & {
  employerId: number;
};

export type CreateEmployerInvitationPayload = {
  first_name: string;
  last_name: string;
  email: string;
  organization_name: string;
  company_node: number;
};

type UpdateEmployerInfoQueryArg = {
  employerId: number;
  newData: Partial<Employer>;
  params?: Record<"set_account_address", boolean>;
};

export type ShareBidsWithEmployerTeamMembersParams = {
  bids: number[];
  team_members: number[];
  message?: string;
};

export type ShareBidsWithEmployerTeamMemberInvitationsParams = {
  bids: number[];
  emails: string[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMembersParams = {
  talent: number;
  team_members: number[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMemberInvitationsParams = {
  talent: number;
  emails: string[];
  message?: string;
};

export type GetATSIntegrationSyncStatusParams = {
  id: number;
  model: EnumType<typeof ENUMS.ATSDataSyncModel>;
};

export type GetATSIntegrationSyncStatusResponse = {
  status: EnumType<typeof ENUMS.MergeSyncStatus>;
  is_initial_sync: boolean;
};

export type GetEmployerByTalentParamsOrderBy = keyof Pick<
  EmployerMyTalent,
  | "email"
  | "country"
  | "expected_hours_per_week"
  | "job_end_date"
  | "job_owner"
  | "job_start_date"
  | "job_title"
  | "last_invoice_amount"
  | "last_invoice_date"
  | "last_name"
  | "name"
  | "rate"
  | "rate_type_label"
  | "role"
  | "state"
  | "status_label"
  | "total_invoiced"
  | "type_label"
  | "ytd_invoice_amount"
  | "bill_rate"
>;

export type GetEmployerByTalentParamsOrderDir = "asc" | "desc";

export type GetEmployerMyTalentParams = Partial<{
  search: string;
  status: Array<EnumType<typeof ENUMS.IsActiveStatus>>;
  jobs: Array<number>;
  job_owners: number[];
  rate_types: Array<EnumType<typeof ENUMS.JobPaymentType>>;
  roles: Array<number>;
  page: number;
  order_by: GetEmployerByTalentParamsOrderBy;
  order_dir: GetEmployerByTalentParamsOrderDir;
}>;

export type GetEmployerMyTalentResponse = PaginatedResult<EmployerMyTalent>;

export type GetEmployerMyTalentStatsResponse = {
  active_talent: number;
  hired_bids_expiring_within_30_days: number;
  open_roles: number;
  total_hired_talent_count: number;
};
export type EmployerSearchMinimalResult = {
  id: number;
  name: string;
};

export type EmployerSearchResult = {
  name_color: string | null;
  link: string;
  full_link: string;
  has_logo_set: boolean;
  logo_background_color: string | null;
  total_jobs: number | null;
  logo: string;
  logo_thumbnail: string;
} & EmployerSearchMinimalResult;

export type EmployerInvoicesTalentSearchResult = {
  id: number;
  talent_name: string;
};

export type EmployerInvoicesTalentSearchParams = {
  search?: string;
};

const assignsJobOwnersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerDefaultJobOwners: build.query<EmployerDefaultJobOwners, number>({
      query: (employerId) => ({
        url: `/manage_employers/${employerId}/get_default_owners/`,
        method: "GET",
      }),
    }),
    changeDefaultOwners: build.mutation<void, NewDefaultJobOwnersPayload>({
      query: ({ employerId, ...ownersData }) => ({
        url: `/manage_employers/${employerId}/change_job_owners/`,
        method: "POST",
        data: { ...ownersData },
      }),
    }),
    createEmployerInvitation: build.mutation<
      void,
      CreateEmployerInvitationPayload
    >({
      query: ({ ...employerData }) => ({
        url: "/employers/",
        method: "POST",
        data: { ...employerData },
      }),
    }),
  }),
});

export const employerApi = API.injectEndpoints({
  endpoints: (build) => ({
    getManagedEmployer: build.query<Employer, { employerId: number }>({
      query: ({ employerId }) => ({
        url: `/manage_employers/${employerId}/`,
        method: "GET",
      }),
      providesTags: ["ManagedEmployers"],
    }),
    getEmployersSearch: build.query<
      EmployerSearchMinimalResult[],
      Record<string, string>
    >({
      query: (params) => ({
        url: `/employer_search/return_id_and_name_only/`,
        method: "GET",
        params: {
          limit: 100,
          ...params,
        },
      }),
    }),
    getEmployerSearchItem: build.query<EmployerSearchResult, number>({
      query: (id) => ({
        url: `/employer_search/${id}/`,
        method: "GET",
      }),
    }),
    getEmployerInvoicesTalent: build.query<
      EmployerInvoicesTalentSearchResult[],
      EmployerInvoicesTalentSearchParams
    >({
      query: (params) => ({
        url: `/employer_invoices/talents/`,
        method: "GET",
        params,
      }),
    }),
    updateEmployerInfo: build.mutation<Employer, UpdateEmployerInfoQueryArg>({
      query: ({ employerId, newData, params }) => {
        return {
          url: `/manage_employers/${employerId}/`,
          method: "PATCH",
          data: newData,
          params: params,
        };
      },
      invalidatesTags: ["ManagedEmployers"],
    }),

    getHasEmployerExceededDailyJobPostingLimit: build.query<
      { has_exceeded_daily_job_posting_limit: boolean },
      { employerId: number }
    >({
      query: ({ employerId }) => ({
        url: `/manage_employers/${employerId}/has_exceeded_daily_job_posting_limit/`,
        method: "GET",
      }),
      providesTags: ["EmployerHasExceededDailyJobsLimit"],
    }),

    getEmployerTeamMembers: build.query<EmployerTeamMemberType[], void>({
      query: () => ({
        url: `/employer_team_members/`,
        method: "GET",
      }),
      keepUnusedDataFor: 10000000,
      providesTags: [{ type: "EmployerTeamMembers", id: "LIST" }],
    }),

    shareBidsWithEmployerTeamMembers: build.mutation<
      void,
      ShareBidsWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_bids/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareBidsWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareBidsWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_bids_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareTalentWithEmployerTeamMembers: build.mutation<
      void,
      ShareTalentWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_talent/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareTalentWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareTalentWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_talent_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),
    getInvitationsToManageEmployer: build.query<InvitedTeamMember[], void>({
      query: () => ({
        url: "/invitations_to_manage_employer/",
        method: "GET",
      }),
      providesTags: ["InvitationsToManageEmployer"],
    }),
  }),
});

const employerTalentApi = API.injectEndpoints({
  endpoints: (build) => {
    return {
      getEmployerMyTalent: build.query<
        GetEmployerMyTalentResponse,
        GetEmployerMyTalentParams | void
      >({
        query: (params) => {
          const processedParams = processGetMyTalentParams(params);

          return {
            url: "/employer_my_talent/",
            method: "GET",
            params: processedParams,
          };
        },
        providesTags: ["EmployerMyTalent"],
        keepUnusedDataFor: 10,
      }),
      getEmployerMyTalentStats: build.query<
        GetEmployerMyTalentStatsResponse,
        void
      >({
        query: () => ({
          url: "/employer_my_talent/stats/",
          method: "GET",
        }),
        providesTags: ["EmployerMyTalentStats"],
      }),
    };
  },
});

type CreateLinkTokenResponse = {
  link_token: string;
  end_user_origin_id: string;
};

type SaveAccountTokenArgs = {
  public_token: string;
  end_user_origin_id: string;
};

type EditATSIntegrationParams = {
  id: number;
} & Pick<ATSIntegration, "auto_fill_job_fields">;

type ATSIntegrationIssuesPrams = {
  id: number;
};

const atsIntegrationApi = API.injectEndpoints({
  endpoints: (build) => ({
    createLinkToken: build.mutation<CreateLinkTokenResponse, void>({
      query: () => ({
        url: `/merge_api/create_link_token/`,
        method: "POST",
      }),
    }),
    saveAccountToken: build.mutation<void, SaveAccountTokenArgs>({
      query: (args) => ({
        url: `/merge_api/save_account_metadata/`,
        method: "POST",
        data: {
          ...args,
        },
      }),
      invalidatesTags: [{ type: "EmployerATSIntegrations", id: "LIST" }],
    }),
    getATSIntegrations: build.query<ATSIntegration[], void>({
      query: () => ({
        url: `/linked_accounts/`,
        method: "GET",
      }),
      providesTags: [{ type: "EmployerATSIntegrations", id: "LIST" }],
    }),
    relinkATSIntegrations: build.query<
      Pick<CreateLinkTokenResponse, "link_token">,
      { integrationId: number }
    >({
      query: ({ integrationId }) => ({
        url: `/linked_accounts/${integrationId}/relink`,
        method: "GET",
      }),
    }),
    deleteATSIntegration: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/linked_accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "EmployerATSIntegrations", id: "LIST" },
        "ManagedEmployers",
      ],
    }),
    editATSIntegration: build.mutation<void, EditATSIntegrationParams>({
      query: ({ id, ...data }) => ({
        url: `/linked_accounts/${id}`,
        method: "PATCH",
        data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { id: updatedIntegrationId, auto_fill_job_fields } = arg;
        const patch = dispatch(
          atsIntegrationApi.util.updateQueryData(
            "getATSIntegrations",
            undefined,
            (draft) => {
              const updatedATSIntergation = draft.find(
                (integration) => integration.id === updatedIntegrationId,
              );
              if (!updatedATSIntergation) {
                return;
              }

              updatedATSIntergation.auto_fill_job_fields = auto_fill_job_fields;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();
          dispatch(
            atsIntegrationApi.util.invalidateTags([
              { type: "EmployerATSIntegrations", id: "LIST" },
            ]),
          );
        }
      },
    }),
    getATSIntegrationErrors: build.query<
      ATSIntegrationError[],
      ATSIntegrationIssuesPrams
    >({
      query: ({ id }) => ({
        url: `/linked_accounts/${id}/issues`,
        method: "GET",
      }),
    }),
    getATSIntegrationJobs: build.query<ATSJob[], number>({
      query: (id) => {
        return {
          url: `/linked_accounts/${id}/synced_jobs/`,
          method: "GET",
        };
      },
      providesTags: ["ATSSyncedJobs"],
    }),
    getATSIntegrationSyncStatus: build.query<
      GetATSIntegrationSyncStatusResponse,
      GetATSIntegrationSyncStatusParams
    >({
      query: ({ id, ...params }) => {
        return {
          url: `/linked_accounts/${id}/sync_status/`,
          method: "GET",
          params,
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "EmployerATSIntegrationStatus", id: arg.id },
      ],
    }),
  }),
});

export const {
  useLazyGetEmployerDefaultJobOwnersQuery,
  useGetEmployerDefaultJobOwnersQuery,
  useChangeDefaultOwnersMutation,
  useCreateEmployerInvitationMutation,
} = assignsJobOwnersApi;

export const {
  useUpdateEmployerInfoMutation,
  useGetEmployerTeamMembersQuery,
  useGetHasEmployerExceededDailyJobPostingLimitQuery,
  useLazyGetHasEmployerExceededDailyJobPostingLimitQuery,
  useShareBidsWithEmployerTeamMemberInvitationsMutation,
  useShareBidsWithEmployerTeamMembersMutation,
  useShareTalentWithEmployerTeamMemberInvitationsMutation,
  useShareTalentWithEmployerTeamMembersMutation,
  useGetEmployersSearchQuery,
  useGetEmployerSearchItemQuery,
  useGetEmployerInvoicesTalentQuery,
  useGetInvitationsToManageEmployerQuery,
} = employerApi;

export const useGetManagedEmployerQuery = getUseQueryHookWithDefaultOptions(
  employerApi.useGetManagedEmployerQuery,
  { refetchOnMountOrArgChange: false },
);

export const { useGetEmployerMyTalentQuery, useGetEmployerMyTalentStatsQuery } =
  employerTalentApi;

export const {
  useCreateLinkTokenMutation,
  useSaveAccountTokenMutation,
  useGetATSIntegrationsQuery,
  useLazyGetATSIntegrationsQuery,
  useDeleteATSIntegrationMutation,
  useEditATSIntegrationMutation,
  useGetATSIntegrationErrorsQuery,
  useGetATSIntegrationJobsQuery,
  useLazyGetATSIntegrationJobsQuery,
  useLazyRelinkATSIntegrationsQuery,
  util: atsIntegrationApiUtil,
  useGetATSIntegrationSyncStatusQuery,
} = atsIntegrationApi;
