import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useEmployerCompanyNode, useEmployerId } from "@js/apps/employer/hooks";
import { useManagedEmployer } from "@js/apps/employer/hooks/managed-employer";
import { Snackbar } from "@js/components/snackbar";

const Stripe = ({ children }) => {
  const companyNode = useEmployerCompanyNode();
  const employerId = useEmployerId();
  const [stripePromise, setStripePromise] = useState<ReturnType<
    typeof loadStripe
  > | null>();

  const { isLoading } = useManagedEmployer();

  const companyNodeSlug = companyNode ? companyNode.slug : null;

  useEffect(() => {
    const setPromise = (nodeSlug) => {
      const stripeLoader = loadStripe(SETTINGS.STRIPE_PUBLIC_KEYS[nodeSlug], {
        locale: "en",
      }).catch(() => {
        Snackbar.error(
          "Having trouble connecting to Stripe, your internet connection may be unstable. " +
            "To make sure all features works correctly please refresh the page.",
          { autoHideDuration: 10000 },
        );
        return null;
      });
      setStripePromise(stripeLoader);
    };

    if (employerId && companyNodeSlug) {
      setPromise(companyNodeSlug);
    } else if ((employerId && !companyNodeSlug) || !employerId) {
      // stripe is required only for employer account type for now
      // also employer can't use stripe until company node is assigned
      setStripePromise(null);
    }
  }, [companyNodeSlug, employerId]);

  if (stripePromise === undefined || isLoading) {
    return <Loader centered />;
  }

  return (
    // we use "key", because stripe prop can't be changed
    // so we need to abandon old DOM tree to render Elements from scratch
    <Elements key={companyNodeSlug} stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default Stripe;
