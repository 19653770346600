import { useEffect } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks/use-user";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import {
  createAiGeneratedJobClicked,
  createCustomJobClicked,
  createJobByUploadClicked,
} from "@js/apps/jobs/actions";
import { Modal } from "@js/components/modal";
import { useAppDispatch, useNavigate } from "@js/hooks";

import { generateJobDescriptionUsingAIInit } from "../../actions";
import { useUploadJobDescriptionModule } from "../../hooks/upload-job-description";
import { useGenerateAIJobPost } from "../../hooks/use-generate-ai-job-post";
import { openCreateJobAiModal } from "../create-job-ai-modal";

import { CreateJobMenu } from "./create-job-menu";
import {
  openUploadLoadingModal,
  UploadLoadingModalInstance,
} from "./upload-loading-modal";

export type CreateJobOption = {
  id: string;
  disabled: boolean;
  icon: `${string}.svg`;
  onClick: VoidFunction;
  title: string;
  subText?: string;
};

export const CreateJobModalInstance = Modal("create-job-modal", {
  keepOnBackdropClick: false,
  className: "create-job-modal",
  closeButton: true,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
  },
});

const CreateJobModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    getInputProps,
    onFileUpload,
    uploadingFileName,
    uploadError,
    isUploading,
    updateJobFormWithFileDataError,
    updateJobFormWithFileDataFinished,
  } = useUploadJobDescriptionModule();

  const { data: employerProfile } = useManagedEmployer();
  const userProfile = useUser();

  const { showGenerateAIButton } = useGenerateAIJobPost();

  useEffect(() => {
    if (isUploading) {
      openUploadLoadingModal({ uploadingFileName });
    } else {
      UploadLoadingModalInstance.close();
    }
  }, [isUploading, uploadingFileName]);

  useEffect(() => {
    if (updateJobFormWithFileDataFinished) {
      navigate("/jobs/add_new");
    }
  }, [navigate, updateJobFormWithFileDataFinished]);

  useEffect(() => {
    return () => CreateJobModalInstance.close();
  }, []);

  const createJobOptions = [
    {
      id: "custom-job",
      disabled: false,
      icon: `${SETTINGS.STATIC_URL}jobs/custom-job-icon.svg`,
      onClick: () => {
        dispatch(createCustomJobClicked());
        navigate("/jobs/add_new");
      },
      title: "Create a custom job",
    },
    {
      id: "ai-job",
      disabled: !showGenerateAIButton,
      icon: `${SETTINGS.STATIC_URL}jobs/ai-job-icon.svg`,
      onClick: () => {
        dispatch(createAiGeneratedJobClicked());
        dispatch(
          generateJobDescriptionUsingAIInit(
            employerProfile?.id || userProfile?.id,
          ),
        ).catch((e) => console.error(e));
        CreateJobModalInstance.close();
        openCreateJobAiModal({ onBack: () => openCreateJobModal() });
      },
      title: "Generate job using AI",
    },
    {
      id: "upload-job",
      disabled: false,
      icon: `${SETTINGS.STATIC_URL}jobs/upload-job-icon.svg`,
      onClick: () => {
        dispatch(createJobByUploadClicked());
        onFileUpload();
      },
      subText: "Max 10 MB",
      title: "Upload existing job description",
    },
  ] satisfies CreateJobOption[];

  return (
    <>
      {!isUploading && (
        <Box>
          <Typography component="h2" fontWeight={500} size="medium">
            Create a job
          </Typography>
          <Typography
            component="h1"
            mt={2}
            fontWeight={400}
            style={{ fontSize: 26 }}
          >
            How would you like to set up your next job?
          </Typography>
          {(uploadError || updateJobFormWithFileDataError) && (
            <Box
              sx={{
                background: "var(--soft-red)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: 1,
                marginBottom: 2,
                width: "fit-content",
                marginTop: 4,
              }}
            >
              <InfoIcon sx={{ color: "var(--negative-2)" }} />
              <Typography
                component="p"
                color="negative"
                size="small"
                fontWeight={500}
              >
                {uploadError || updateJobFormWithFileDataError}
              </Typography>
            </Box>
          )}
          <CreateJobMenu createJobOptions={createJobOptions} />
          <input
            data-testid="upload-job-description-input"
            {...getInputProps()}
            style={{ display: "none" }}
          />
        </Box>
      )}
      <UploadLoadingModalInstance />
    </>
  );
};

export const openCreateJobModal = () =>
  CreateJobModalInstance.open({
    onClose: () => CreateJobModalInstance.close(),
    children: <CreateJobModal />,
  });
