import type { AnyAction } from "@reduxjs/toolkit";

import {
  AVATAR_FIELD_UPLOADING,
  CLEAR_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
  FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
} from "@js/apps/employer/action-types";

import type { EmployerState } from "../../types/employer";

const INITIAL_STATE: EmployerState = {
  openJobs: [],
  fetchingOpenJobs: false,
  fetchingEmployerPublicProfile: false,
  employerPublicProfile: undefined,
  avatarFieldUploading: false,
};

export default (state = INITIAL_STATE, action: AnyAction): EmployerState => {
  switch (action.type) {
    case FETCH_EMPLOYER_PUBLIC_PROFILE:
      return {
        ...state,
        fetchingEmployerPublicProfile: true,
      };

    case FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS:
      return {
        ...state,
        employerPublicProfile: action.payload,
        fetchingEmployerPublicProfile: false,
      };

    case CLEAR_EMPLOYER_PUBLIC_PROFILE:
      return {
        ...state,
        employerPublicProfile: undefined,
      };

    case FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED:
      return {
        ...state,
        employerPublicProfile: undefined,
        fetchingEmployerPublicProfile: true,
      };

    case AVATAR_FIELD_UPLOADING:
      return {
        ...state,
        avatarFieldUploading: action.payload,
      };

    default:
      return state;
  }
};
