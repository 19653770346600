import { useMemo } from "react";
import { SubmissionError } from "redux-form";
import _ from "underscore";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { updateFreelancerProfile } from "@js/apps/freelancer/actions";
import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";
import {
  prepareUpdateFreelancerCustomizationExternalProfilesValue,
  prepareUpdateFreelancerProfileValues,
} from "@js/apps/freelancer/hooks/account-info/utils";
import { useAppDispatch } from "@js/hooks";
import type { Freelancer } from "@js/types/freelancer";

import {
  freelancerApi,
  useGetFreelancerExternalProfilesQuery,
  useUpdateFreelancerCustomizationMutation,
} from "../../api";

export const useAccountInfo = (profile: Freelancer | undefined) => {
  const dispatch = useAppDispatch();
  const { data: externalProfiles } = useGetFreelancerExternalProfilesQuery();

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const initialValues = useMemo<AccountInfoFormData>(
    () => ({
      user: {
        first_name: profile?.user?.first_name || "",
        last_name: profile?.user?.last_name || "",
      },
      external_profiles: externalProfiles,
      full_location: profile?.location || null,
    }),
    [
      externalProfiles,
      profile?.location,
      profile?.user.first_name,
      profile?.user.last_name,
    ],
  );

  const onSubmit = async (values: AccountInfoFormData) => {
    if (!profile) return;

    const preparedProfileValues = prepareUpdateFreelancerProfileValues(values);

    await dispatch(
      updateFreelancerProfile({
        freelancerId: profile.id,
        updatedValues: preparedProfileValues,
      }),
    );

    const preparedExternalProfiles =
      prepareUpdateFreelancerCustomizationExternalProfilesValue(values);

    try {
      await updateFreelancerCustomization({
        id: profile.id,
        data: {
          external_profiles: preparedExternalProfiles,
          user: _.omit(profile.user, "introduction", "introduction_headline"),
        },
      }).unwrap();
    } catch (error: any) {
      throw new SubmissionError(error.data);
    }
  };

  const onSubmitSuccess = () => {
    if (!profile) return;
    dispatch(fetchCurrentUser());
    dispatch(
      freelancerApi.util.invalidateTags(["FreelancerProfileCompletion"]),
    );
  };

  return {
    formConfig: {
      onSubmit,
      onSubmitSuccess,
      initialValues,
      enableReinitialize: true,
    },
  };
};
