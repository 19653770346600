import React, { useMemo } from "react";
import { SubmissionError } from "redux-form";

import { Loader } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUpdateEmployerInfoMutation } from "@js/apps/employer/api";
import { useEmployerId, useManagedEmployer } from "@js/apps/employer/hooks";
import { useAppDispatch } from "@js/hooks";

import type { VisibilityFormValues } from "../../forms/visibility";
import { VisibilityForm } from "../../forms/visibility";

export const EmployerVisibility = () => {
  const employerId = useEmployerId();
  const dispatch = useAppDispatch();

  const { data: employerProfile, isLoading: isLoadingEmployerProfile } =
    useManagedEmployer();
  const [updateEmployerInfo] = useUpdateEmployerInfoMutation();

  const onSubmit = async (
    values: Pick<VisibilityFormValues, "show_number_of_jobs">,
  ) => {
    if (!employerId) {
      return;
    }

    try {
      await updateEmployerInfo({
        employerId,
        newData: values,
      }).unwrap();

      dispatch(fetchCurrentUser());
    } catch (e: any) {
      throw new SubmissionError(e.data);
    }
  };

  const initialValues = useMemo(() => {
    return {
      show_number_of_jobs: employerProfile?.show_number_of_jobs,
    };
  }, [employerProfile]);

  if (!employerProfile || isLoadingEmployerProfile) {
    return <Loader centered />;
  }

  return (
    <VisibilityForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      accountType={ENUMS.AccountType.EMPLOYER}
    />
  );
};
