import { useCallback, useEffect, useRef } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useLazyGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import { isACHPaymentMethod } from "@js/apps/payments/utils";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerOfferBid } from "@js/types/jobs";
import type { PaymentMethod } from "@js/types/payments";

import {
  CreateOrEditOfferSnackbarContentMethodFailed,
  CreateOrEditOfferSnackbarContentUnVerified,
  CreateOrEditOfferSnackbarContentVerified,
} from "../../components";
import type {
  CreateOfferDataReturnData,
  UpdateOfferDataReturnData,
} from "../../types";

type OnSuccessResultData = (
  | UpdateOfferDataReturnData
  | CreateOfferDataReturnData
) & {
  transactionError?: Record<string, string>;
};

type UseCreateOrEditOfferSnackbarProps = {
  jobId: number | undefined;
  bidDetails: EmployerOfferBid | undefined;
};

type DisplaySnackbarConfig = {
  result: OnSuccessResultData;
  depositPaymentMethod: PaymentMethod | undefined;
};

export const useCreateOrEditOfferSnackbar = ({
  jobId,
  bidDetails,
}: UseCreateOrEditOfferSnackbarProps) => {
  const userName = bidDetails?.freelancer.user.first_name;
  const { data: employerProfile } = useManagedEmployer();
  const currentOfferDeposit = employerProfile?.current_offer_deposit;

  const storedCurrentOfferDeposit = useRef(currentOfferDeposit);

  useEffect(() => {
    storedCurrentOfferDeposit.current = currentOfferDeposit;
  }, [currentOfferDeposit, storedCurrentOfferDeposit]);

  const [getStorageValue, { isLoading: loadingTopBar }] =
    useLazyGetStorageValueQuery();

  const displayOfferSnackbar = useCallback(
    async ({ result, depositPaymentMethod }: DisplaySnackbarConfig) => {
      if (depositPaymentMethod) {
        const isTransactionError = !!result.transactionError;
        const isSameMethod =
          storedCurrentOfferDeposit.current?.payment_method_id ===
          depositPaymentMethod.id;
        const isPreviousDepositFailed =
          storedCurrentOfferDeposit.current?.deposit_status ===
            ENUMS.OfferDepositStatus.PAYMENT_FAILED && isSameMethod;

        if ((isTransactionError || isPreviousDepositFailed) && jobId) {
          // We need to fetch top bar storage value here in case of error in order
          // to display notification and top bar without delay between them
          await getStorageValue({
            key: FRONTEND_STORAGE_KEYS.PAYMENT_METHOD_FAILED_TOP_BAR,
          });

          Snackbar.toast({
            header: `🚨 Your payment method has failed so we are not able to send your
                offer`,
            bgcolor: "var(--medium-red)",
            content: (closeSnackbar) => {
              return (
                <CreateOrEditOfferSnackbarContentMethodFailed
                  closeSnackbar={closeSnackbar}
                  offerId={result.id}
                  jobId={jobId}
                />
              );
            },
          });

          return;
        }

        const _isACHPaymentMethod = isACHPaymentMethod(depositPaymentMethod);
        const isDepositNotSent =
          storedCurrentOfferDeposit.current?.deposit_status ===
          ENUMS.OfferDepositStatus.PAYMENT_IN_PROGRESS;

        if (_isACHPaymentMethod || isDepositNotSent) {
          Snackbar.toast({
            header: `⌛ Your offer has not been sent yet`,
            content: () => {
              return (
                <CreateOrEditOfferSnackbarContentUnVerified
                  userName={userName}
                />
              );
            },
          });

          return;
        }
      }

      Snackbar.toast({
        header: `Your offer was sent to ${userName} 📬`,
        content: (closeSnackbar) => {
          return (
            <CreateOrEditOfferSnackbarContentVerified
              closeSnackbar={closeSnackbar}
              bidDetails={bidDetails}
            />
          );
        },
      });
    },
    [bidDetails, getStorageValue, jobId, userName],
  );

  return { displayOfferSnackbar, loadingTopBar };
};
