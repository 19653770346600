import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import type { Dispatch } from "redux";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { isObject, omit } from "underscore";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import { useFetchInitialOfferQuery } from "@js/apps/jobs/apps/bids/api/api";
import { useGetPaymentMethodsQuery } from "@js/apps/payments/api";
import { useHandleTransaction } from "@js/apps/payments/hooks/use-handle-transaction";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useGoBackHistory, useNavigate } from "@js/hooks/";
import { useIdParam } from "@js/hooks/use-id-param";
import { deleteMakeOfferMessage } from "@js/services/local-storage";
import type { Employer } from "@js/types/employer";

import { offerMade } from "../../actions";
import { useCreateEmployerOfferMutation } from "../../api";
import {
  ReviewBeforeSendingModal,
  ReviewBeforeSendingModalContent,
} from "../../components";
import { OFFER_FIELDS } from "../../constants";
import type {
  CreateOfferDataReturnData,
  CreateOfferFormData,
} from "../../types";
import {
  getCreateOfferInitialValues,
  getInitialPaymentMethodOnCreateOffer,
} from "../../utils";
import { useCreateOrEditOfferSnackbar } from "../create-or-edit-offer-snackbar";

type OnSuccessResultData = CreateOfferDataReturnData & {
  transactionError?: Record<string, string>;
};

export const useCreateOffer = (): {
  loading: boolean;
  isTransactionProceeding: boolean;
  onSubmit: (values: CreateOfferFormData) => void;
  onSubmitSuccess: (
    result: OnSuccessResultData | undefined,
    methodDispatch: Dispatch<any>,
    props: DecoratedFormProps<CreateOfferFormData>,
  ) => void;
  onSubmitFail: () => void;
  initialValues: Partial<CreateOfferFormData>;
  employerProfile: Employer | undefined;
} => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goBack = useGoBackHistory();

  const jobId = useIdParam();
  const bidId = useIdParam("bidId");

  const isNodeStaff = useIsNodeStaff();

  const [createOffer] = useCreateEmployerOfferMutation();
  const {
    data: initialOffer,
    isLoading: isLoadingInitialOffer,
    error: fetchingOfferError,
  } = useFetchInitialOfferQuery(bidId ? { bidId } : skipToken);

  const { handleTransactionCreated, loading: transactionLoading } =
    useHandleTransaction();

  const {
    data: employerProfile,
    isFetching: isFetchingEmployerProfile,
    refetch: refetchEmployerProfile,
  } = useManagedEmployer();

  const isDepositRequired = employerProfile?.offer_deposit_required;
  const currentOfferDeposit = employerProfile?.current_offer_deposit;

  const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined, {
    skip: isNodeStaff,
  });

  const bidDetails = initialOffer?.bid;

  const { displayOfferSnackbar, loadingTopBar } = useCreateOrEditOfferSnackbar({
    jobId,
    bidDetails,
  });

  const paymentMethod = useMemo(() => {
    if (!paymentMethods) return;

    return getInitialPaymentMethodOnCreateOffer(
      isDepositRequired,
      paymentMethods,
    );
  }, [isDepositRequired, paymentMethods]);

  useEffect(() => {
    const onInit = async () => {
      try {
        if (isLoadingInitialOffer) return;

        if (fetchingOfferError) {
          // in case of error user will be redirected immediately because of axios interceptor
          const errorMessage =
            (isObject(fetchingOfferError) &&
              fetchingOfferError?.data?.detail) ||
            "Could not load offer";

          throw Error(errorMessage);
        }
      } catch (error) {
        const _error = error as { response?: { data: string } };

        Snackbar.error(_error.response?.data || "Something went wrong");
        // Don't redirect immediately to make sure the user sees the error in the context of the visited offer.
        setTimeout(() => {
          const lastPage = new URLSearchParams(location.state?.prevSearch).get(
            "page",
          );
          const pagination = lastPage ? `?page=${lastPage}` : "";
          goBack(`/jobs/${jobId}/proposals/${pagination}`);
        }, 3000);
      }
    };
    onInit();
  }, [
    fetchingOfferError,
    goBack,
    isLoadingInitialOffer,
    jobId,
    location.state?.prevSearch,
  ]);

  const onSubmit = useCallback(
    async (values: CreateOfferFormData) => {
      const data = await createOffer({
        ...omit(values, "deposit_payment_method"),
        deposit_payment_method_id: isDepositRequired
          ? values.deposit_payment_method?.id || null
          : undefined,
      })
        .unwrap()
        .catch((err) => {
          if (err.data._error) {
            Snackbar.error(err.data._error);
          }

          throw new SubmissionError({
            ...err.data,
            [OFFER_FIELDS.deposit_payment_method]:
              err.data.deposit_payment_method_id,
          });
        });

      if (data.payment_transaction && values.deposit_payment_method) {
        const transactionData = await handleTransactionCreated(
          data.payment_transaction,
          values.deposit_payment_method,
        );

        // We should use try catch here but right now we can't as
        // transaction is linked to offer and returned only if offer is created
        // which will cause that we will not be redirected even if offer was created successfully
        // TODO: Try to use try catch when deposit will be migrated to employer level
        if (transactionData?.error) {
          return {
            transactionError: transactionData.error,
            ...data,
          };
        }
      }

      return data;
    },
    [createOffer, handleTransactionCreated, isDepositRequired],
  );

  const onSubmitSuccess = useCallback(
    async (
      result: OnSuccessResultData | undefined,
      methodDispatch: Dispatch<any>,
      props: DecoratedFormProps<CreateOfferFormData>,
    ) => {
      const values = props.values as CreateOfferFormData;

      if (!result) {
        ReviewBeforeSendingModal.open({
          children: (
            <ReviewBeforeSendingModalContent
              onSubmit={() => {
                if (!props.change) return;
                methodDispatch(props.change("dry_run", false));
              }}
              values={values}
            />
          ),
        });

        return;
      }

      deleteMakeOfferMessage(Number(bidId));

      dispatch(offerMade(result.id));

      ReviewBeforeSendingModal.close();

      if (!isNodeStaff) {
        refetchEmployerProfile();
      }

      const lastPage = new URLSearchParams(location.state?.prevSearch).get(
        "page",
      );
      const pagination = lastPage ? `?page=${lastPage}` : "";

      navigate(`/jobs/${jobId}/proposals/${pagination}`);

      const depositPaymentMethod =
        values.deposit_payment_method ||
        paymentMethods?.find(
          (method) => method.id === currentOfferDeposit?.payment_method_id,
        );

      displayOfferSnackbar({ result, depositPaymentMethod });
    },
    [
      bidId,
      currentOfferDeposit?.payment_method_id,
      dispatch,
      displayOfferSnackbar,
      isNodeStaff,
      jobId,
      location.state?.prevSearch,
      navigate,
      paymentMethods,
      refetchEmployerProfile,
    ],
  );

  const onSubmitFail = useCallback(() => {
    ReviewBeforeSendingModal.close();
  }, []);

  const initialValues = useMemo(() => {
    return getCreateOfferInitialValues(initialOffer, paymentMethod);
  }, [initialOffer, paymentMethod]);

  return {
    loading:
      isLoadingInitialOffer ||
      transactionLoading ||
      isFetchingEmployerProfile ||
      loadingTopBar,
    initialValues: initialValues || {},
    isTransactionProceeding: transactionLoading,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    employerProfile,
  };
};
