import { skipToken } from "@reduxjs/toolkit/query";

import { useGetManagedEmployerQuery } from "@js/apps/employer/api";
import { useEmployerId } from "@js/apps/employer/hooks/employer-id";

// We unfortunately can't use Parameters<typeof useGetManagedEmployerQuery>[1] here, as it breaks the return type.
// In case we need to use other query options, we should update the type manually.
type QueryOptions = {
  refetchOnMountOrArgChange?: boolean;
};

export const useManagedEmployer = (queryOptions?: QueryOptions) => {
  const employerId = useEmployerId();

  return useGetManagedEmployerQuery(
    employerId ? { employerId } : skipToken,
    queryOptions,
  );
};
