import { SubmissionError } from "redux-form";
import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

import type { GridColDef } from "@hexocean/braintrust-ui-components/components/DataGrid";
import {
  AVATAR_FIELD_UPLOADING,
  CLEAR_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
  FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
} from "@js/apps/employer/action-types";
import { Events } from "@js/services/analytics/constants";
import type { AppDispatch } from "@js/store";
import { type AppThunkAction } from "@js/store";
import type { UserMinimalProfile } from "@js/types/auth";
import type { WithRecaptchaCode } from "@js/types/common";
import type { Employer } from "@js/types/employer";
import type { AIGeneratedJob, GenerateAIJobPayload } from "@js/types/jobs";

import type {
  ExportCSVCustomDateRange,
  ExportCSVRangeOptionValue,
} from "./components/employer-invoices/export-employer-invoices-modal/helpers";
import {
  EXPORT_CSV_RANGE_OPTION_LABELS,
  EXPORT_CSV_RANGE_OPTION_VALUE,
} from "./components/employer-invoices/export-employer-invoices-modal/helpers";
import { employerApi } from "./api";

export const fetchEmployerPublicProfile =
  (id: number): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_EMPLOYER_PUBLIC_PROFILE,
      });

      return axios
        .get(`/api/employers/${id}/`)
        .then((response) => {
          dispatch({
            type: FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
          });

          return reject(error.response.data);
        });
    });

export const deleteEmployerTeamMemberInvitation = (id: number) =>
  new Promise((resolve, reject) =>
    axios
      .delete(`/api/invitations_to_manage_employer/${id}/`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );

export const resendEmployerTeamMemberInvitation = ({
  id,
  ...restValues
}: WithRecaptchaCode<{ id: number }>) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/invitations_to_manage_employer/${id}/resend/`, restValues)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data)),
  );

export const addEmployerTeamMember = (values: { email: string }) =>
  new Promise((resolve, reject) =>
    axios
      .post("/api/manage_employers/invite_new_user/", values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );

export const deleteEmployerTeamMember =
  (id: UserMinimalProfile["id"]) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/remove_team_member/`, { user: id })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
              "JobSubscribers",
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const preventEmployerTeamMemberInvoiceView =
  (id: number) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/prevent_from_viewing_invoices/`, {
          user: id,
        })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const allowEmployerTeamMemberInvoiceView =
  (id: number) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/allow_to_view_invoices/`, { user: id })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const generateJobDescriptionUsingAIInit =
  (employerId?: Employer["id"]): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) => {
      return axios
        .post("/api/job_post_assistant/initial_request/", {
          short_description: "",
          employer_id: employerId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

export const generateJobDescriptionUsingAI =
  (
    employerId?: GenerateAIJobPayload["employer_id"],
    jobOverview: GenerateAIJobPayload["short_description"] = "",
  ): AppThunkAction<Promise<AIGeneratedJob>> =>
  () =>
    new Promise((resolve, reject) => {
      return axios
        .post("/api/job_post_assistant/validate/", {
          short_description: jobOverview,
          employer_id: employerId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          return reject(
            new SubmissionError({
              _error:
                error?.response?.data?.detail ||
                "Oops! It seems there's an issue generating a job description. Please try again later",
            }),
          );
        });
    });

export const clearEmployerPublicProfile = () => ({
  type: CLEAR_EMPLOYER_PUBLIC_PROFILE,
});

export const avatarFieldUploading = (isLoading: boolean) => ({
  type: AVATAR_FIELD_UPLOADING,
  payload: isLoading,
});

export const shareApplicantClicked = createAction(Events.CLICK_SHARE_APPLICANT);

export const shareApplicantBulkClicked = createAction(
  Events.CLICK_SHARE_APPLICANT_BULK,
);

export const shareProfileClicked = createAction(Events.CLICK_SHARE_PROFILE);

export const clickSyncJobsATSModal = createAction(
  Events.CLICK_SYNC_JOBS_ATS_MODAL,
);

export const clickSyncATSHomeDashboard = createAction(
  Events.CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD,
);

export const clickNavATSIntegration = createAction(
  Events.CLICK_NAV_ATS_INTEGRATION,
);

export const clickAddIntegrationATS = createAction(
  Events.CLICK_ADD_INTEGRATION_ATS,
);

export const clickResyncATS = createAction(Events.CLICK_RESYNC_ATS);

export const clickDisconnectATS = createAction(Events.CLICK_DISCONNECT_ATS);

export const clickSelectJobsATS = createAction(Events.CLICK_SELECT_JOBS_ATS);

export const clickMoveToDraftsATS = createAction(
  Events.CLICK_MOVE_TO_DRAFTS_ATS,
);

export const clickPublishJobsATS = createAction(Events.CLICK_PUBLISH_JOBS_ATS);

export const myTalentSortOrder = ({
  columns,
  sortBy,
}: {
  columns: GridColDef[];
  sortBy: string;
}) => {
  const sortByFieldLabel =
    columns
      .find((columnDef) => columnDef.field === sortBy)
      ?.headerName?.toLocaleLowerCase() ?? sortBy;
  return {
    type: Events.MY_TALENT_SORT_ORDER,
    payload: { sort_filter: sortByFieldLabel },
  };
};

export const filterMyTalentSearch = ({
  filterType,
  filterValue,
}: {
  filterType: string;
  filterValue: unknown;
}) => {
  return {
    type: Events.FILTER_MY_TALENT_SEARCH,
    payload: { filter_type: filterType, filter_value: filterValue },
  };
};

export const clickMyTalentSearch = () => {
  return {
    type: Events.CLICK_MY_TALENT_SEARCH,
  };
};

export const clickMyTalentViewJobs = () => {
  return {
    type: Events.CLICK_MY_TALENT_VIEW_JOBS,
  };
};

export const clickMyTalentShowTalent = () => {
  return {
    type: Events.CLICK_MY_TALENT_SHOW_TALENT,
  };
};

export const clickMyTalentShowContracts = () => {
  return {
    type: Events.CLICK_MY_TALENT_SHOW_CONTRACTS,
  };
};

export const clickMyTalentCSV = () => {
  return {
    type: Events.CLICK_MY_TALENT_CSV,
  };
};

export const clickMyTalentColumns = ({
  columnOptions,
  selectedColumns,
}: {
  columnOptions: GridColDef[];
  selectedColumns: string[];
}) => {
  const selectedColumnsNames = selectedColumns.map((columnField) => {
    const columnDef = columnOptions.find(
      (myTalentTableColumnDef) => myTalentTableColumnDef.field === columnField,
    );
    return columnDef?.headerName?.toLocaleLowerCase() ?? columnField;
  });

  return {
    type: Events.CLICK_MY_TALENT_COLUMNS,
    payload: { columns: selectedColumnsNames },
  };
};

export const employerInvoicesSortOrderChanged = ({
  columns,
  sortBy,
}: {
  columns: GridColDef[];
  sortBy: string;
}) => {
  const sortByFieldLabel =
    columns
      .find((columnDef) => columnDef.field === sortBy)
      ?.headerName?.toLocaleLowerCase() ?? sortBy;

  return {
    type: Events.INVOICE_SORT_ORDER,
    payload: { sort_filter: sortByFieldLabel },
  };
};

export const filterEmployerInvoicesSearchApplied = ({
  filterType,
  filterValue,
}: {
  filterType: string;
  filterValue: unknown;
}) => {
  return {
    type: Events.FILTER_INVOICES_SEARCH,
    payload: { filter_type: filterType, filter_value: filterValue },
  };
};

export const employerInvoicesSearchApplied = () => {
  return {
    type: Events.CLICK_INVOICES_SEARCH,
  };
};

export const processExportCSVDateRangeForTracking = (
  value: ExportCSVRangeOptionValue,
  customRange: ExportCSVCustomDateRange | undefined,
): string => {
  switch (value) {
    case EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE: {
      const stringifiedCustomRange = [
        customRange?.date_after,
        customRange?.date_before,
      ]
        .map((date) => date ?? "...")
        .join(" - ");

      return `${EXPORT_CSV_RANGE_OPTION_LABELS[value]?.toLocaleLowerCase()} ${stringifiedCustomRange}`;
    }
    default:
      return (
        EXPORT_CSV_RANGE_OPTION_LABELS[value]?.toLocaleLowerCase() ?? value
      );
  }
};

export const employerInvoicesCSVClicked = (
  value: ExportCSVRangeOptionValue,
  customRange: ExportCSVCustomDateRange | undefined,
) => {
  const dateRange = processExportCSVDateRangeForTracking(value, customRange);

  return {
    type: Events.CLICK_INVOICE_CSV,
    payload: { date_range: dateRange },
  };
};

export const downloadEmployerInvoicesClicked = () => {
  return {
    type: Events.CLICK_DOWNLOAD_INVOICES,
  };
};

export const employerInvoicesColumnsChanged = ({
  columnOptions,
  selectedColumns,
}: {
  columnOptions: Array<{ label: string; value: string }>;
  selectedColumns: string[];
}) => {
  const selectedColumnsNames = selectedColumns.map((selectedColumn) => {
    const columnDef = columnOptions.find(
      (columnOption) => columnOption.value === selectedColumn,
    );
    return columnDef?.label?.toLocaleLowerCase() ?? selectedColumn;
  });

  return {
    type: Events.CLICK_INVOICES_COLUMNS,
    payload: { columns: selectedColumnsNames },
  };
};
